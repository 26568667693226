import Api from "./Api.js";
import regression from "regression";
import { loc, removeDuplicates, includesMultiType, isNullable, getLangFromLocale, getUnitFromLocale } from "./helpers";

import mixpanel from 'mixpanel-browser';
class Moba {
  constructor() {
    this.loadingStack = 0;
    this.loading = true;
    this.details = {};
    this.entity = null;
    this.entity_details = null;
    this.entity_name = null;
    this.quota = 0;
    this.subscription = 0;
    this.certificates = [];
    this.certificates_filtered = [];
    this.email = "";
    this.typical_vehicle_type = null;
    this.vehicle_model = null;
    this.odometer_model = null;
    this.age_model = null;
    this.soh_model = null;
    this.average_data = null;
    this.certificates_yearly = null;
    this.number_certificates_yearly = [];
    this.current_year_number_certificates = null;
    this.current_year_average_soh = null;
    this.current_year_average_age = null;
    this.current_year_average_odometer = null;
    this.number_certificates_diff = null;
    this.average_soh_diff = null;
    this.average_age_diff = null;
    this.average_odometer_diff = null;
    this.pie_chart_percent_age = null;
    this.pie_chart_percent_odometer = null;
    this.pie_chart_percent_soh = null;
    this.pie_chart_percent_brand = null;
    this.pie_chart_percent_model = null;
    this.certificates_by_month = null;
    this.degration_by_age = null;
    this.number_of_diagnostics_by_team = null;
    this.number_of_diagnostics_by_dongle = null;
    this.number_of_diagnostics_by_site = null;
    this.number_of_diagnostics_by_delegator = null;
    this.number_of_diagnostics_by_delegation = null;
    this.roles_assignable = null;
    this.role = null;
    this.sites = null;
    this.percent_soh_under_85 = null;
    this.percent_soh_over_85 = null;
    this.percent_electric = null;
    this.percent_phev = null;
    this.lang = "en";
    this.unit = "km";
    this.resultsFetchAnalytics = null;
    this.resultFetchCertificates = null;
    this.resultFetchEntityInfo = null;
    this.resultFetchSites = null;
    this.resultFetchUsers = null;
    this.resultFetchTeams = null;
    this.resultFetchDongles = null;
    this.resultsCertificatesYearly = null;
    this.all_sites = [];
    this.available_countries = [];
    this.available_locales = [];
  }

  isSuperAdmin() {
    return this.role.id === "Super Admin";
  }

  isLoading() {
    return this.loadingStack > 0;
  }

  handleLocaleChange(locale) {
    const lang = getLangFromLocale(locale);
    const unit = getUnitFromLocale(locale);

    this.lang = lang;
    this.unit = unit;

    Api.lang = lang;
  }

  logout() {
    new Api().fetch("logout", {}, (response) => {
      if (response.err === 0) {
        window.location.href = "/";
      }
    });
  }

  async setDetails(details) {
    let locale = details.user.lang;
    this.handleLocaleChange(locale);

    this.details = details;
    this.entity = details.user.entity.id;
    this.entity_name = details.user.entity.name;
    this.quota =
      loc("Mes crédits disponibles", this.lang) +
      ": " +
      details.user.entity.quota;
    this.subscription = details.user.entity.subscription;
    if (details.user.entity.subscription) {
      this.quota = loc("Compte abonné", this.lang);
    }
    this.roles_assignable = details.user.roles_assignable;
    this.roles_assignable = this.roles_assignable.map(
      (r) => this.getRoleFromRoleID(r)
    );
    this.role = this.getRoleFromRoleID(details.user.role);
    this.all_sites = details.user.sites.map((site) => {
      return {
        id: site.id,
        name: site.name,
      };
    });
  }
  setName(first_name, last_name) {
    this.details.user.firstname = first_name;
    this.details.user.lastname = last_name;
  }
  setEmail(email) {
    this.email = email;
  }
  getEmail() {
    if (this.details.user === undefined) return null;
    return this.details.user.email;
  }
  teamFullName(team, teams = null) {
    if (teams === null) {
      teams = this.getAllTeams();
    }
    return teams.filter(t => t.name === team.name).length > 1 ? `${team.name} (${team.id})` : team.name
  }
	updateInfos = (cb, forceFetch = false) => {
		let process = async result => {
			this.entity_details = result.entity;
			this.entity_details.users = this.entity_details.users.map(user => {
				user.teams_export = user.teams.map(team => {
					return team.team;
				}).join(",");
				user.sites_export = user.sites.map(s => s.name).join(",");
				user.sites_export = user.sites_export;
				return user;
			});
			this.entity_details.teams = this.entity_details.teams.map(team => {
				team.name = loc(team.name, this.lang);
				return team;
			});
			await this.updateSites();
      await this.fetchAvailableCountries();
      await this.fetchAvailableLocales();

			this.loadingStack -= 1;
			cb();
		};
    this.loadingStack += 1;
		if (this.resultFetchEntityInfo === null || forceFetch) {
			new Api().fetch("mobaEntityInfo", {}, result => {
				this.resultFetchEntityInfo = result;
				process(result);
			});
		} else {
			process(this.resultFetchEntityInfo);
		}
	};
	async fetchCertificates(cb, force = false) {
		this.loadingStack += 1;
		var process = results => {
			results = results.map(certificate => {
				certificate.date_diag = new Date(
					certificate.date_diag_timestamp * 1000
				)
				//Remove seconds
					.toLocaleString('fr', {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					});
				return certificate;
			});
			results = results.map(certificate => {
				certificate.team = loc(certificate.team, this.lang);
				return certificate;
			});
			this.certificates = results;
			this.certificates_filtered = this.certificates;
			this.loadingStack -= 1;
			cb();
		};
		if (this.resultFetchCertificates === null || force) {
			new Api().fetch("mobaGetCertificatesFiltered", {}, results => {
				//we convert data_diag timestamp, which is in seconds, to locale date
				this.resultFetchCertificates = results;
				process(results);
			});
		} else {
			process(this.resultFetchCertificates);
		}
	}
	fetchCertificatesAnalytics(cb) {
		this.loadingStack += 1;
		if (true) {
			new Api().fetch("mobaGetAnalyticsCertificates", {}, results => {
				this.resultsFetchAnalytics = results;
				results = results.map(certificate => {
					certificate.team = loc(certificate.team, this.lang);
					return certificate;
				});
				this.certificates = results;
				this.certificates_filtered = this.certificates;
				this.loadingStack -= 1;
				cb();
			});
		} else {
			var results = this.resultsFetchAnalytics;
			results = results.map(certificate => {
				certificate.team = loc(certificate.team, this.lang);
				return certificate;
			});
			this.certificates = results;
			this.certificates_filtered = this.certificates;
			cb();
		}
	}
	fetchCertificatesYearly(sites, countries, dates, cb) {
    const filters = {};
    if (sites !== null) {
      filters.sites = sites.map(site => site.id);
    }
    if (countries !== null) {
      filters.countries = countries.map(country => country.id);
    }
    if (dates !== null) {
      filters.dates = dates;
    }
		new Api().fetch(
			"mobaGetDiagnosticsComparison", 
			filters,
			results => {
				this.certificates_yearly = results;
				this.percent_soh_under_85 = results["n"].metrics.soh_under_85_perc ? parseInt(results["n"].metrics.soh_under_85_perc) : null;
				this.percent_soh_over_85 = results["n"].metrics.soh_over_85_perc ? parseInt(results["n"].metrics.soh_over_85_perc) : null;
				this.percent_electric = results["n"].metrics.electric_perc ? parseInt(results["n"].metrics.electric_perc) : null;
				this.percent_phev = results["n"].metrics.phev_perc ? parseInt(results["n"].metrics.phev_perc) : null;
				cb();
			}
		);
	}
	updateSites() {
		this.entity_details.sites = this.entity_details.sites.map(site => {
			if (site.admin_team == null) {
				site.admin_team = {};
				site.admin_team.members = [];
			}
            if (site.billing_addresses) {
                site.billing_addresses_export = site.billing_addresses.street + ", " + site.billing_addresses.postal_code + " " + site.billing_addresses.city
            } else {
                site.billing_addresses_export = ""
            }
			site.site_managers_export = site.admin_team.members
				.map(member => {
					if (member.role === "manager") return member.user;
					else return null;
				})
				.filter(member => member !== null)
				.join(",");
			site.site_admins_export = site.admin_team.members
				.map(member => {
					if (member.role === "admin") return member.user;
					else return null;
				})
				.filter(member => member !== null)
				.join(",");
			site.site_managers = site.admin_team.members
				.filter(member => member.role === "manager")
			site.site_admins = site.admin_team.members
				.filter(member => member.role === "admin")
			return site;
		});
	}
	getCertificates() {
		return this.certificates;
	}
	filterCertificates(filters = null) {
    if (filters === null) {
      filters = {};
    }
    const { site, team, country, date, cost } = filters

    this.certificates_filtered = this.certificates;

		if (date && date.length === 2 && date[0] && date[1]) {
      const startDate = date[0].setHours(0, 0, 0, 0);
      const endDate = date[1].setHours(23, 59, 59, 999);
			this.certificates_filtered = this.certificates.filter(
				certificate => {
					return (
						certificate.date_diag_timestamp > startDate / 1000 &&
						certificate.date_diag_timestamp < endDate / 1000
					);
				}
			);
		} 

    if (country) {
      const allowEmpty = includesMultiType(null, country, "id");
      this.certificates_filtered = this.certificates_filtered.filter(
        certificate => {
          return includesMultiType(certificate.country, country, 'id') || (isNullable(certificate.country) && allowEmpty);
        }
      );
    }

    if (team) {
      const allowEmpty = includesMultiType(null, team, "id");
      this.certificates_filtered = this.certificates_filtered.filter(
        certificate => {
          return includesMultiType(certificate.team, team, 'id') || (isNullable(certificate.team) && allowEmpty);
        }
      );
    }

    if (site) {
      const allowEmpty = includesMultiType(null, site, "id");
      this.certificates_filtered = this.certificates_filtered.filter(
        certificate => {
          return includesMultiType(certificate.site, site, 'id') || (isNullable(certificate.site) && allowEmpty);
        }
      );
    }

    if (cost) {
      this.certificates_filtered = this.certificates_filtered.filter(
        certificate => {
          return includesMultiType(certificate.cost, cost, 'id');
        }
      );
    }
	}
  loadAllAnalyticsData() {
    this.numberOfDiagnosticsByTeam();
    this.numberOfDiagnosticsByDongle();
    this.numberOfDiagnosticsByUser();
    this.numberOfDiagnosticsBySite();
    this.numberOfDiagnosticsByDelegator();
    this.numberOfDiagnosticsByDelegation();
    this.getTypicalVehicleType();
    this.getPieChartSoh();
    this.getPieChartModel();
    this.getPieChartOdometer();
    this.getPieChartAge();
    //this.state.moba.getPieChartBrand();
    // this.getCertificateNumberByTeam(year);
    this.getCertificateNumberByTeam();
    this.getDegradationByAge();
    this.getAllSites();
  }

  getAllTypes() {
    const results = [];

    this.certificates_filtered
      .forEach((certificate) => {
        let type;
        if (
          !results
            .map((result) => {
              return result.name;
            })
            .includes(certificate.model_without_version)
        ) {
          type = {};
          type.name = certificate.model_without_version;
          type.number = 1;
          type.site = certificate.site;
          type.team = certificate.team;
          type.country = certificate.country;
          type.cost = certificate.cost;
          results.push(type);
        } else {
          type = results.filter((type) => {
            return type.name === certificate.model_without_version;
          })[0];
          type.number++;
        }
      });
    return results;
  }

  getTypicalVehicleType() {
    const averageData = this.calculateAverageData();
    const typeWithMaxDiags = averageData.reduce((max, type) => {
      return type.number > max.number ? type : max;
    }, { number: 0 });
    this.typical_vehicle_type = typeWithMaxDiags;
    return typeWithMaxDiags;

  }
  getOdometerModel(vehicle_type = null) {
    let odometerSum = 0;
    let odometerCount = 0;
    this.certificates_filtered.forEach((diag) => {
      if (vehicle_type && diag.model_without_version !== vehicle_type) {
        return;
      }
      if (diag.odometer && diag.odometer > 0 && diag.odometer !== '--') {
        odometerSum += diag.odometer;
        odometerCount++;
      }
    });
    const avg = odometerCount > 0 ? odometerSum / odometerCount : null;
    return {
      avg: avg,
      sum: odometerSum,
      count: odometerCount,
    }
  }
  getAgeModel(vehicle_type = null) {
    let ageSum = 0;
    let ageCount = 0;
    this.certificates_filtered.forEach((diag) => {
      if (vehicle_type && diag.model_without_version !== vehicle_type) {
        return;
      }
      if (diag.age_month !== undefined && diag.age_month !== null) {
        ageSum += diag.age_month;
        ageCount++;
      }
    });
    const avg = ageCount > 0 ? ageSum / ageCount : null;
    return {
      avg: avg,
      sum: ageSum,
      count: ageCount,
    }
  }
  getSohModel(vehicle_type = null) {
    let sohSum = 0;
    let sohCount = 0;
    this.certificates_filtered.forEach((diag) => {
      if (vehicle_type && diag.model_without_version !== vehicle_type) {
        return;
      }
      if (diag.soh && diag.soh > 0) {
        sohSum += diag.soh;
        sohCount++;
      }
    });
    const avg = sohCount > 0 ? sohSum / sohCount : null;
    return {
      avg: avg,
      sum: sohSum,
      count: sohCount,
    }
  }
  getDegradationModel(vehicle_type = null) {
    let degradationSum = 0;
    let degradationCount = 0;
    this.certificates_filtered.forEach((diag) => {
      if (vehicle_type && diag.model_without_version !== vehicle_type) {
        return;
      }
      if (diag.age_month && diag.soh !== undefined && diag.soh !== null) {
        const age_year = diag.age_month / 12;
        const degradation = (100 - diag.soh) / age_year;
        degradationSum += degradation;
        degradationCount++;
      }
    });
    const avg = degradationCount > 0 ? degradationSum / degradationCount : null;
    return {
      avg: avg,
      sum: degradationSum,
      count: degradationCount,
    }
  }
  getDiagsCountByVehicleType(vehicle_type = null) {
    return this.certificates_filtered.filter((diag) => {
      return !vehicle_type || diag.model_without_version === vehicle_type;
    }).length;
  }
  
  calculateAverageData() {
    const types = this.getAllTypes();
    types.map((type) => {
      type.soh = this.getSohModel(type.name);
      type.odometer = this.getOdometerModel(type.name);
      type.age = this.getAgeModel(type.name);
      type.degradation = this.getDegradationModel(type.name);
      return type;
    });
    this.average_data = types;
    return types;
  }
  calculateGlobalAverageData() {
    const soh = this.getSohModel();
    const odometer = this.getOdometerModel();
    const age = this.getAgeModel();
    const degradation = this.getDegradationModel();
    const modelsCount = this.getAllTypes().length;
    const diagsCount = this.getDiagsCountByVehicleType();
    return { soh, odometer, age, degradation, modelsCount, diagsCount };
  }
  buildChartDatasetData(dates, diagnostics, yearOffset = 0, unit = 'month') {
    const [from, to] = dates;
    const fromDate = new Date(from);
    const toDate = new Date(to);

    if (!unit) {
      unit = 'month';
    }

    if (yearOffset) {
      fromDate.setFullYear(fromDate.getFullYear() + yearOffset);
      toDate.setFullYear(toDate.getFullYear() + yearOffset);
    }

    const diagsByUnit = {};

    const lang = this.lang.replace("_", "-");

    const monthFormatter = new Intl.DateTimeFormat(lang, {
      month: "long",
    });

    const dayFormatter = new Intl.DateTimeFormat(lang, {
      day: "numeric",
    });

    // Initialize data object by assiging keys which are months (monthIndex + 1 * year) between from and to

    const dateKey = (date) => {
      const monthIndex = date.getMonth();
      const dayIndex = date.getDate();

      const monthIndexWithPrepending0 = monthIndex < 9 ? `0${monthIndex + 1}` : monthIndex + 1;
      const dayIndexWithPrepending0 = dayIndex < 10 ? `0${dayIndex}` : dayIndex;

      const year = date.getFullYear();
      let key;
      if (unit === 'day') {
        key = parseInt(`${year}${monthIndexWithPrepending0}${dayIndexWithPrepending0}`);
      } else if (unit === 'month') {
        key = parseInt(`${year}${monthIndexWithPrepending0}`);
      }
      return key;
    }

    const toDateKey = dateKey(toDate);
    let fromDatekey = dateKey(fromDate);
    while (fromDatekey <= toDateKey) {
      const fromYear = fromDate.getFullYear();
      const fromMonth = monthFormatter.format(fromDate);
      const fromDay = dayFormatter.format(fromDate);

      const fromDateWithoutOffset = new Date(fromDate);
      fromDateWithoutOffset.setFullYear(fromYear - yearOffset);
      const fromYearWithoutOffset = fromDateWithoutOffset.getFullYear();
      const fromMonthWithoutOffset = monthFormatter.format(fromDateWithoutOffset);
      const fromDayWithoutOffset = dayFormatter.format(fromDateWithoutOffset);

      let label;
      let labelWithOffset;
      if (unit === 'month') {
        label = `${fromMonthWithoutOffset} ${fromYearWithoutOffset}`;
        labelWithOffset = `${fromMonth} ${fromYear}`;
      } else if (unit === 'day') {
        label = `${fromDayWithoutOffset} ${fromMonthWithoutOffset} ${fromYearWithoutOffset}`;
        labelWithOffset = `${fromDay} ${fromMonth} ${fromYear}`;
      }

      diagsByUnit[fromDatekey] = {
        count: 0,
        label,
        labelWithOffset,
      };

      if (unit === 'day') {
        fromDate.setDate(fromDate.getDate() + 1);
      } else if (unit === 'month') {
        fromDate.setMonth(fromDate.getMonth() + 1);
      }
      fromDatekey = dateKey(fromDate);
    }

    diagnostics.forEach((diag) => {
      const diagDate = new Date(diag.date_diag_timestamp * 1000);
      const diagKey = dateKey(diagDate);
      if (diagsByUnit[diagKey]) {
        diagsByUnit[diagKey].count++;
      }
    })

    const datasetData = [];

    Object.keys(diagsByUnit).sort().forEach((key) => {
      datasetData.push(
        {
          x: diagsByUnit[key].label,
          y: diagsByUnit[key].count,
          xWithOffset: diagsByUnit[key].labelWithOffset,
        }
      )
    })

    return datasetData;
  }
  computeYearlyCertificates(dates) {
    let nYear;
    let nMiunus1Year;
    if (new Date(dates[0]).getFullYear() === new Date(dates[1]).getFullYear()) {
      nYear = new Date(dates[0]).getFullYear()
      nMiunus1Year = new Date(dates[0]).getFullYear() - 1;
    } else {
      nYear = `${new Date(dates[0]).getFullYear()}-${new Date(dates[1]).getFullYear()}`;
      nMiunus1Year = `${new Date(dates[0]).getFullYear() - 1}-${new Date(dates[1]).getFullYear() - 1}`;
    }

    const number_certificates_yearly = {};

    const diffBetweenDatesInDays = (new Date(dates[1]) - new Date(dates[0])) / (1000 * 60 * 60 * 24);

    let unit;
    if (diffBetweenDatesInDays < 60) {
      unit = 'day';
    } else {
      unit = 'month';
    }

    number_certificates_yearly[nYear] = this.buildChartDatasetData(
      dates,
      this.certificates_yearly["n"].diagnostics,
      0,
      unit
    );

    number_certificates_yearly[nMiunus1Year] = this.buildChartDatasetData(
      dates,
      this.certificates_yearly["n-1"].diagnostics,
      -1,
      unit
    );
    this.number_certificates_yearly = number_certificates_yearly;
  }
  getPieChartSoh() {
    var percent = [0, 0, 0, 0, 0];
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.soh === null) {
        return;
      }
      if (certificate.soh < 65) percent[0]++;
      else if (certificate.soh < 75) percent[1]++;
      else if (certificate.soh < 85) percent[2]++;
      else if (certificate.soh < 95) percent[3]++;
      else percent[4]++;
    });
    this.pie_chart_percent_soh = percent;
  }
  getPieChartAge() {
    var percent = [0, 0, 0, 0, 0];
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.age_month === null) {
        return;
      }
      if (certificate.age_month === undefined) return;
      if (certificate.age_month < 12) percent[0]++;
      else if (certificate.age_month < 24) percent[1]++;
      else if (certificate.age_month < 36) percent[2]++;
      else if (certificate.age_month < 48) percent[3]++;
      else percent[4]++;
    });
    this.pie_chart_percent_age = percent;
    //if percent only has 0 values
    if (percent.every((value) => value === 0))
      this.pie_chart_percent_age = null;
  }
  getPieChartModel() {
    var models_unique = this.certificates_filtered
      .map((certificate) => certificate.model_without_version)
      .filter((value, index, self) => self.indexOf(value) === index);
    var percent = {};
    models_unique.forEach((model) => {
      var number = 0;
      this.certificates_filtered.forEach((certificate) => {
        if (certificate.model_without_version === model) number++;
      });
      percent[model] = number;
    });
    this.pie_chart_percent_model = percent;
  }
  getPieChartOdometer() {
    var percent = [0, 0, 0, 0, 0];
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.age_month === null) {
        return;
      }
      if (certificate.odometer < 10000) percent[0]++;
      else if (certificate.odometer < 30000) percent[1]++;
      else if (certificate.odometer < 80000) percent[2]++;
      else if (certificate.odometer < 150000) percent[3]++;
      else percent[4]++;
    });
    this.pie_chart_percent_odometer = percent;
  }
  getAllTeams() {
    return removeDuplicates(this.certificates_filtered.map((c) => c.team)).filter(t => !!t);
  }
  getAllDelegators() {
    return removeDuplicates(this.certificates_filtered.map((c) => c.delegator), 'entity.id').filter(d => !!d);
  }
  getAllDelegations() {
    return removeDuplicates(this.certificates_filtered.map((c) => c.delegation), 'entity.id').filter(d => !!d);
  }

  getAllDongles() {
    return removeDuplicates(
      this.certificates_filtered.map(c => c.dongle).filter(d => !!d)
    );
  }

  getAllUsers() {
    return removeDuplicates(
      this.certificates_filtered.map(c => c.user).filter(u => u?.toLowerCase().indexOf("anonym") === -1)
    );
  }

  getAllSites() {
    return removeDuplicates(
      this.certificates_filtered.map(c => c.site).filter(s => !!s)
    );
  }

  getCertificateNumberByTeam(year = null) {
    var teams = this.getAllTeams();
    let teamName;
    //add team Inconnue to teams
    teams.push({id: 0, name: loc("Inconnue", this.lang)});
    var number = {};
    teams.forEach((team) => {
      teamName = this.teamFullName(team, teams);
      number[teamName] = [];
      for (var i = 0; i < 12; i++) number[teamName].push(0);
    });

    const currentDate = new Date();
    this.certificates_filtered.forEach((certificate) => {
      let isRightTimeFrame;
      let monthsDiff;
      if (year === null) {
        const certifDate = new Date(certificate.date_diag_timestamp * 1000);
        monthsDiff =
          (currentDate.getFullYear() - certifDate.getFullYear()) * 12 +
          (currentDate.getMonth() - certifDate.getMonth());
        isRightTimeFrame = monthsDiff <= 12;
      } else {
        certificate.year = Number(certificate.year);
        isRightTimeFrame = certificate.year === year;
      }

      if (isRightTimeFrame) {
        if (certificate.team === undefined) {
          teamName = loc("Inconnue", this.lang);
        } else {
          teamName = this.teamFullName(certificate.team, teams);
        }
        const monthIndex = (year === null) ? 11 - monthsDiff : this.getIndexOfMonth(certificate.month);
        number[teamName][monthIndex]++;
      }
    });
    //if team inconnue has no certificates, delete it
    if (number[loc("Inconnue", this.lang)].every((value) => value === 0))
      delete number[loc("Inconnue", this.lang)];
    this.certificates_by_month = number;
  }

  getIndexOfMonth(month) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(month);
  }
  getDegradationByAge() {
    var result = [];
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.age_month !== undefined && certificate.soh !== undefined)
        result.push([certificate.age_month, certificate.soh]);
    });

    result = regression.linear(result);

    var max = Math.max(
      ...this.certificates_filtered.map((certificate) => {
        if (certificate.age_month === undefined) return 0;
        return certificate.age_month;
      })
    );

    this.degration_by_age = [result.equation, max];
    /*
		this.degration_by_age = [
			this.interpolateArray(
				result.points.map(([x, y]) => {
					return y;
				}),
				max
			),
			max,
		];
		*/
  }
  interpolateArray(array, length) {
    var result = [];
    var x = 0;
    var y = 0;
    var dx = 0;
    var dy = 0;
    var step = 1 / length;
    for (var i = 0; i < length; i++) {
      x = i * step;
      y = array[Math.floor(x)];
      dx = x - Math.floor(x);
      dy = array[Math.ceil(x)] - y;
      result.push(y + dy * dx);
    }

    return result;
  }
  numberOfDiagnosticsByTeam() {
    const teams = this.getAllTeams();

    var number = {};
    teams.forEach((team) => {
      number[this.teamFullName(team, teams)] = 0;
    });
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.team !== undefined) {
        number[this.teamFullName(certificate.team, teams)]++;
      }
    });
    this.number_of_diagnostics_by_team = number;
  }
  numberOfDiagnosticsByDelegator() {
    const delegators = this.getAllDelegators();

    const number = {};
    delegators.forEach((deleg) => {
      number[deleg.entity.name] = 0;
    });
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.delegator) {
        number[certificate.delegator.entity.name]++;
      }
    });
    this.number_of_diagnostics_by_delegator = number;
  }
  numberOfDiagnosticsByDelegation() {
    const delegations = this.getAllDelegations();

    const number = {};
    delegations.forEach((deleg) => {
      number[deleg.entity.name] = 0;
    });
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.delegation) {
        number[certificate.delegation.entity.name]++;
      }
    });
    this.number_of_diagnostics_by_delegation = number;
  }
  numberOfDiagnosticsByDongle() {
    function normalizeDongleName(dongle) {
      if (typeof dongle !== "string") return dongle;

      const dongleMatch = dongle.match(/^[a-zA-Z]+_0*([1-9][0-9]*)$/);
      if (dongleMatch) {
        return dongleMatch[1];
      }
      return dongle;
    }

    var dongles = this.getAllDongles();
    var number = {};
    dongles.forEach((dongle) => {
      number[normalizeDongleName(dongle)] = 0;
    });
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.dongle !== undefined) {
        number[normalizeDongleName(certificate.dongle)]++;
      }
    });
    this.number_of_diagnostics_by_dongle = number;
  }
  numberOfDiagnosticsByUser() {
    var users = this.getAllUsers();
    var number = {};
    users.forEach((user) => {
      number[user] = 0;
    });
    //add team Anonyme to teams
    number[loc("Anonyme", this.lang)] = 0;

    this.certificates_filtered.forEach((certificate) => {
      if (certificate.user) {
        if (certificate.user.toLowerCase().indexOf("anonym") === -1) {
          number[certificate.user]++;
        } else {
          number[loc("Anonyme", this.lang)]++;
        }
      }
    });
    if (number[loc("Anonyme", this.lang)] === 0)
      delete number[loc("Anonyme", this.lang)];
    this.number_of_diagnostics_by_user = number;
  }
  numberOfDiagnosticsBySite() {
    var sites = this.getAllSites();
    var number = {};
    sites.forEach((site) => {
      number[site.name] = 0;
    });
    this.certificates_filtered.forEach((certificate) => {
      if (certificate.site !== undefined) {
        number[certificate.site.name]++;
      }
    });
    this.number_of_diagnostics_by_site = number;
  }
  deleteUser(user_id, cb) {
    new Api().fetch("deleteUser", { user_id: user_id }, (response) => {
      cb(response);
    });
  }
  deleteSite(site_id, cb) {
    new Api().fetch("deleteSite", { site_id: site_id }, (response) => {
      cb(response);
    });
  }
  async fetchAvailableCountries() {
    if (this.available_countries.length > 0) return;

    const api = new Api();
    const availableCountries = await api.fetch("getAvailableCountries", {});
    if (!availableCountries.err || availableCountries.err === 0) {
      availableCountries.map((c) => {
        c.id = c.code;
      });
      this.available_countries = availableCountries;
    }
  }
  async fetchAvailableLocales() {
    if (this.available_locales.length > 0) return;

    const api = new Api();
    const availableLocales = await api.fetch("getAvailableLocales", {});
    if (!availableLocales.err || availableLocales.err === 0) {
      this.available_locales = availableLocales;
    }
  }

  getEntityDetailsProperty(property, key = 'id', options = null) {
    if (options === null) {
      options = {};
    }
    const { filter, map, indexed } = options;

    let propertyValues = this.entity_details[property] || [];

    if (map) {
      propertyValues = propertyValues.map(map);
    }

    if (filter) {
      propertyValues = propertyValues.filter(filter);
    }

    propertyValues = removeDuplicates(propertyValues, key);

    propertyValues = JSON.parse(JSON.stringify(propertyValues))

    if (indexed) {
      const indexedValues = {};
      propertyValues.forEach((item, index) => {
        indexedValues[item[key]] = item;
      });
      return indexedValues;
    }
    return propertyValues;
  }
  getFilterableCountries(indexed = false) {
    const countries = this.getEntityDetailsProperty('sites', 'id', {
      indexed,
      map: (site) => this.getCountryFromCode(site.country),
      filter: (country) => country !== null
    });
    return countries;
  }

  getFilterableTeams(indexed = null) {
    const teams = this.getEntityDetailsProperty('teams', 'id', {
      indexed,
      filter: (team) => {
        return team.name !== "Admin" && team.name !== "Site Admin";
      }
    });
    return teams;
  }
  getFilterableSites(indexed = null) {
    const sites = this.getEntityDetailsProperty('sites', 'id', { indexed });
    return sites;
  }
  getFilterableUsers(indexed = null) {
    const users = this.getEntityDetailsProperty('users', 'id', { indexed });
    return users;
  }
  getFilterableDongles(indexed = null) {
    let dongles = this.getEntityDetailsProperty('dongles', 'id', { indexed });
    Object.keys(dongles).forEach((key) => {
      dongles[key].name = dongles[key].code;
    });
    return dongles;
  }
  getFilterableRoles(indexed = null) {
    const roles = this.getEntityDetailsProperty('users', 'id', {
      indexed,
      map: (user) => this.getRoleFromRoleID(user.role),
    });
    return roles;
  }
  getFilterableDelegators(indexed = null) {
    const delegators = this.getEntityDetailsProperty('teams', 'id', { 
      indexed, 
      map: (team) => team.delegator?.entity,
      filter: (ent) => !!ent
    });
    return delegators;
  }

  getFilterableDelegations(indexed = null) {
    const delegations = this.getEntityDetailsProperty('teams', 'id', {
      indexed, 
      map: (team) => team.delegation?.entity,
      filter: (ent) => !!ent
    });
    return delegations;
  }

  getUnknownCountry() {
    return {
      code: null,
      name: loc("Pays inconnu", this.lang),
      emoji: "🌍",
    };
  }

  getCountryFromCode(code) {
    const country = this.available_countries.find(
      (country) => country.id === code
    );
    if (!country) {
      return null
    }
    return country;
  }

  getLocaleFromCode(code) {
    const locale = this.available_locales.find(
      (locale) => locale.id === code
    );
    return locale;
  }

  getRoleFromRoleID(roleId) {
    return {
      id: roleId,
      name: loc(roleId, this.lang)
    }
  }
}
export default Moba;
